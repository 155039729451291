import {
    Container,
    Typography,
    Grid
} from '@mui/material';

import Title from './title';
import Context from './context';

export default function TeamFormation() {
    return (
        <div id='team-formation'>
            <Container sx={{ my: 20 }}>
                <Title>Баг бүрдүүлэлт</Title>
                <Grid container justifyContent='space-between' rowSpacing={4}>
                    <Grid item xs={12} md={6}>
                        <Context>
                            Дадлагажих хөтөлбөрт баг тус бүр <b>5-н </b>
                            гишүүнтэй байх бөгөөд бүртгүүлсэн багуудаас
                            шигшигдсэн 20-н багийг сонгоно.
                        </Context>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Typography variant='h4'>
                            <b>Багийн бүрэлдэхүүн</b>
                        </Typography>
                        <Typography variant='h6'>
                            <ul>
                                <li>1 Front-End Developer</li>
                                <li>3 Back-End Developer</li>
                                <li>1 Product Manager</li>
                            </ul>
                        </Typography>
                    </Grid>
                </Grid>
            </Container>
        </div>
    )
}