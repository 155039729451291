import Slider from 'react-slick';
import { Box, Container } from '@mui/material';

import Title from './title';

import ApexImage from '../assets/company-logos/apex.png';
import ApuImage from '../assets/company-logos/apu.png';
// import CapitronImage from '../assets/company-logos/capitron.png';
import GolomtImage from '../assets/company-logos/golomt.png';
import JururImage from '../assets/company-logos/jurur.png';
import OchirUndraaOMZImage from '../assets/company-logos/ochir-undraa-omz.png';
// import TechStoreImage from '../assets/company-logos/techstore.png';
import UBGroupImage from '../assets/company-logos/ub-group.png';
// import XacImage from '../assets/company-logos/xac.png';
import YoshinoyaImage from '../assets/company-logos/yoshinoya.png';
import DoctorAutoChainImage from '../assets/company-logos/doctor-auto-chain.png';
import NominMotorsImage from '../assets/company-logos/nomin-motors.png';

var sliderSettings = {
    dots: true,
    arrows: false,
    infinite: true,
    autoplay: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    swipeToSlide: true,
    responsive: [
        {
            breakpoint: 1200,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
            }
        },
        {
            breakpoint: 900,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                initialSlide: 2
            }
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ]
};

function CompanyItem(props) {
    return (
        <Box
            sx={{
                position: 'relative',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center',
                width: '200px',
                height: '150px',
                transition: '0.3s',
                mx: 'auto',
                filter: 'brightness(0) invert(0.8)',
                '&:hover': {
                    transform: 'scale(1.2)',
                    transition: '0.3s',
                },
            }}
        >
            {props.children}
        </Box>
    )
}

export default function Companies() {
    return (
        <div id='companies'>
            <Container>
                <Title>Дадлагажих байгууллагууд</Title>
                <Slider {...sliderSettings}>
                    {/* <CompanyItem>
                        <img src={XacImage} alt='xac' width='80%' />
                    </CompanyItem> */}
                    <CompanyItem>
                        <img src={ApuImage} alt='apu' width='80%' />
                    </CompanyItem>
                    {/* <CompanyItem>
                        <img src={CapitronImage} alt='capitron' width='80%' />
                    </CompanyItem> */}
                    <CompanyItem>
                        <img src={GolomtImage} alt='golomt' width='80%' />
                    </CompanyItem>
                    <CompanyItem>
                        <img src={JururImage} alt='jurur' width='60%' />
                    </CompanyItem>
                    <CompanyItem>
                        <img src={YoshinoyaImage} alt='yoshinoya' width='80%' />
                    </CompanyItem>
                    <CompanyItem>
                        <img src={ApexImage} alt='apex' width='50%' />
                    </CompanyItem>
                    <CompanyItem>
                        <img src={OchirUndraaOMZImage} alt='jurur' width='100%' />
                    </CompanyItem>
                    {/* <CompanyItem>
                        <img src={TechStoreImage} alt='jurur' width='90%' />
                    </CompanyItem> */}
                    <CompanyItem>
                        <img src={UBGroupImage} alt='ub-group' width='80%' />
                    </CompanyItem>
                    <CompanyItem>
                        <img src={DoctorAutoChainImage} alt='doctor-auto-chain' width='80%' />
                    </CompanyItem>
                    <CompanyItem>
                        <img src={NominMotorsImage} alt='nomin-motors' width='80%' />
                    </CompanyItem>
                </Slider>
            </Container>
        </div>
    )
}