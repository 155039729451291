import {
    Container,
    Grid,
} from '@mui/material';

import Title from './title';
import Context from './context';

export default function Program() {
    return (
        <div id='program'>
            <Container sx={{ my: 10 }}>
                <Title>Хөтөлбөр</Title>
                <Grid container>
                    <Grid item xs={12} md={12}>
                        <Context>
                            Мэдээлэл технологийн чиглэлээр сурч буй оюутан, залуу
                            авьяастнуудад <b>Цахиурын хөндийд</b> технологи боловсруулалтаараа
                            өрсөлдөж буй Монгол инженерүүдийн бүтээсэн Эрксис платформ дээр
                            суурилан <b>Монголын ТОП компаниудын</b> үйл ажиллагааг
                            автоматжуулах, асуудлуудыг шийдэх дижитал шийдлүүдийг хөгжүүлж,
                            бүтээх замаар ур чадвар, мэдлэгээ нэмэгдүүлэх боломжийг олгоно.
                        </Context>
                    </Grid>
                </Grid>
            </Container>
        </div>
    )
}