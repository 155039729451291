import { Typography } from "@mui/material";

export default function Context(props) {
    const { children } = props

    return (
        <Typography variant='h5'>
            <span className='tag'>&lt;p&gt;</span>
                {children}
            <span className='tag'>&lt;/p&gt;</span>
        </Typography>
    )
}