import {
    Box,
    Container,
    Divider,
    Grid,
    Typography,
} from '@mui/material';
import {
    Timeline,
    TimelineItem,
    TimelineSeparator,
    TimelineConnector,
    TimelineContent,
    TimelineOppositeContent,
    TimelineDot,
} from '@mui/lab';

import Title from './title';
import CircleIcon from '@mui/icons-material/Circle';

import BootcampImg from '../assets/timeline/bootcamp.png';
import BasketballImg from '../assets/timeline/basketball.png';
import FootballImg from '../assets/timeline/football.png';
import QuizNightImg from '../assets/timeline/quiznight.png';
import Dota2Img from '../assets/timeline/dota2.png';
import ClosureImg from '../assets/timeline/closure.png';

function TopVerticalLine() {
    return (
        <Divider
            sx={{
                backgroundColor: 'white',
                width: '35px',
                transform: 'rotate(90deg)',
                position: 'relative',
                left: '-5px',
                top: '10px'
            }}
        />
    )
}

function LastTopVerticalLine() {
    return (
        <Divider
            sx={{
                backgroundColor: 'white',
                width: '35px',
                transform: 'rotate(90deg)',
                position: 'absolute',
                right: '-5px',
                top: '10px'
            }}
        />
    )
}

function BottomVerticalLine() {
    return (
        <Divider sx={{
            backgroundColor: 'white',
            width: '35px',
            transform: 'rotate(90deg)',
            position: 'relative',
            left: '-5px',
            top: '50px'
        }} />
    )
}

export default function Timelines() {
    return (
        <div>
            <Container>
                <Title>Timeline</Title>

                {/* DESKTOP TIMELINE - START */}
                <Box sx={{ my: 20, display: { xs: 'none', md: 'initial' } }}>
                    <Grid container alignItems='' sx={{ mt: 10 }}>
                        <Grid item xs={2} sx={{ position: 'relative' }}>
                            <img src={BootcampImg} alt='Bootcamp' />

                            <CircleIcon sx={{ position: 'absolute', left: '0px', top: '142.5px' }} />
                        </Grid>
                        <Grid item xs={2} sx={{ position: 'relative' }}>
                            <Typography variant='h6'>
                                <b><span className='tag'>06/17</span><br /></b>
                                Сагсан бөмбөгийн тэмцээн
                            </Typography>

                            <CircleIcon sx={{ position: 'absolute', left: '0px', top: '142.5px' }} />
                        </Grid>
                        <Grid item xs={2} sx={{ position: 'relative' }}>
                            <img src={FootballImg} alt='Football' />

                            <CircleIcon sx={{ position: 'absolute', left: '0px', top: '142.5px' }} />
                        </Grid>
                        <Grid item xs={2} sx={{ position: 'relative' }}>
                            <Typography variant='h6'>
                                <b><span className='tag'>07/22</span><br /></b>
                                Quiz Night
                            </Typography>

                            <CircleIcon sx={{ position: 'absolute', left: '0px', top: '142.5px' }} />
                        </Grid>
                        <Grid item xs={2} sx={{ position: 'relative' }}>
                            <img src={Dota2Img} alt='Dota 2' />

                            <CircleIcon sx={{ position: 'absolute', left: '0px', top: '142.5px' }} />
                        </Grid>
                        <Grid item xs={2} sx={{ position: 'relative', textAlign: 'right' }}>
                            <Typography variant='h6'>
                                <b><span className='tag'>08/26</span><br /></b>
                                Хаалтын үйл ажиллагаа
                            </Typography>

                            <CircleIcon sx={{ position: 'absolute', right: '0px', top: '142.5px' }} />
                        </Grid>
                        <Grid item xs={2} sx={{ position: 'relative' }}>
                            <BottomVerticalLine />
                        </Grid>
                        <Grid item xs={2} sx={{ position: 'relative' }}>
                            <TopVerticalLine />
                        </Grid>
                        <Grid item xs={2} sx={{ position: 'relative' }}>
                            <BottomVerticalLine />
                        </Grid>
                        <Grid item xs={2} sx={{ position: 'relative' }}>
                            <TopVerticalLine />
                        </Grid>
                        <Grid item xs={2} sx={{ position: 'relative' }}>
                            <BottomVerticalLine />
                        </Grid>
                        <Grid item xs={2} sx={{ position: 'relative', textAlign: 'right' }}>
                            <LastTopVerticalLine />
                        </Grid>
                        <Grid item xs={12} sx={{ my: 4 }}>
                            <Divider
                                sx={{
                                    backgroundColor: 'white',
                                    width: '97%',
                                    mx: 'auto'
                                }}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <Typography variant='h6'>
                                <b><span className='tag'>05/28-29</span><br /></b>
                                Bootcamp сургалт
                            </Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <img src={BasketballImg} alt='Basketball' />
                        </Grid>
                        <Grid item xs={2}>
                            <Typography variant='h6'>
                                <b><span className='tag'>07/02</span><br /></b>
                                Хөлбөмбөгийн тэмцээн
                            </Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <img src={QuizNightImg} alt='Quiz Night' />
                        </Grid>
                        <Grid item xs={2}>
                            <Typography variant='h6'>
                                <b><span className='tag'>08/06</span><br /></b>
                                DOTA 2 тэмцээн
                            </Typography>
                        </Grid>
                        <Grid item xs={2} sx={{ textAlign: 'right' }}>
                            <img src={ClosureImg} alt='Closure' />
                        </Grid>
                    </Grid>
                </Box>
                {/* DESKTOP TIMELINE - END */}

                {/* MOBILE TIMELINE - START */}
                <Box sx={{ my: 20, display: { xs: 'initial', md: 'none' } }}>
                    <Timeline position="alternate">
                        <TimelineItem>
                            <TimelineOppositeContent sx={{ m: 'auto 0' }} align="right">
                                <Typography variant='body2'>
                                    <b><span className='tag'>05/28-29</span><br /></b>
                                    Bootcamp сургалт
                                </Typography>
                            </TimelineOppositeContent>
                            <TimelineSeparator>
                                <TimelineConnector />
                                    <TimelineDot />
                                <TimelineConnector />
                            </TimelineSeparator>
                            <TimelineContent sx={{ py: '12px', px: 2 }}>
                                <img src={BootcampImg} alt='Bootcamp' />
                            </TimelineContent>
                        </TimelineItem>
                        <TimelineItem>
                            <TimelineOppositeContent sx={{ m: 'auto 0' }}>
                                <Typography variant='body2'>
                                    <b><span className='tag'>06/17</span><br /></b>
                                    Сагсан бөмбөгийн тэмцээн
                                </Typography>
                            </TimelineOppositeContent>
                            <TimelineSeparator>
                                <TimelineConnector />
                                    <TimelineDot />
                                <TimelineConnector />
                            </TimelineSeparator>
                            <TimelineContent sx={{ py: '12px', px: 2 }}>
                                <img src={BasketballImg} alt='Basketball' />
                            </TimelineContent>
                        </TimelineItem>
                        <TimelineItem>
                            <TimelineOppositeContent sx={{ m: 'auto 0' }} >
                                <Typography variant='body2'>
                                    <b><span className='tag'>07/02</span><br /></b>
                                    Хөлбөмбөгийн тэмцээн
                                </Typography>
                            </TimelineOppositeContent>
                            <TimelineSeparator>
                                <TimelineConnector />
                                    <TimelineDot />
                                <TimelineConnector />
                            </TimelineSeparator>
                            <TimelineContent sx={{ py: '12px', px: 2 }}>
                                <img src={FootballImg} alt='Football' />
                            </TimelineContent>
                        </TimelineItem>
                        <TimelineItem>
                            <TimelineOppositeContent sx={{ m: 'auto 0' }}>
                                <Typography variant='body2'>
                                    <b><span className='tag'>07/22</span><br /></b>
                                    Quiz Night
                                </Typography>
                            </TimelineOppositeContent>
                            <TimelineSeparator>
                                <TimelineConnector />
                                    <TimelineDot />
                                <TimelineConnector />
                            </TimelineSeparator>
                            <TimelineContent sx={{ py: '12px', px: 2 }}>
                                <img src={QuizNightImg} alt='Quiz Night' />
                            </TimelineContent>
                        </TimelineItem>
                        <TimelineItem>
                            <TimelineOppositeContent sx={{ m: 'auto 0' }} >
                                <Typography variant='body2'>
                                    <b><span className='tag'>08/06</span><br /></b>
                                    DOTA 2 тэмцээн
                                </Typography>
                            </TimelineOppositeContent>
                            <TimelineSeparator>
                                <TimelineConnector />
                                    <TimelineDot />
                                <TimelineConnector />
                            </TimelineSeparator>
                            <TimelineContent sx={{ py: '12px', px: 2 }}>
                                <img src={Dota2Img} alt='Dota 2' />
                            </TimelineContent>
                        </TimelineItem>
                        <TimelineItem>
                            <TimelineOppositeContent sx={{ m: 'auto 0' }}>
                                <Typography variant='body2'>
                                    <b><span className='tag'>08/26</span><br /></b>
                                    Хаалтын үйл ажиллагаа
                                </Typography>
                            </TimelineOppositeContent>
                            <TimelineSeparator>
                                <TimelineConnector />
                                    <TimelineDot />
                                <TimelineConnector />
                            </TimelineSeparator>
                            <TimelineContent sx={{ py: '12px', px: 2 }}>
                                <img src={ClosureImg} alt='Closure' />
                            </TimelineContent>
                        </TimelineItem>
                    </Timeline>
                </Box>
                {/* MOBILE TIMELINE - END */}
            </Container>
        </div>
    )
}