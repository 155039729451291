import Menu from '../components/menu';
import Landing from '../components/landing';
import Program from '../components/program';
import Benefits from '../components/benefits';
import Companies from '../components/companies';
import TeamFormation from '../components/team-formation';
import Timeline from '../components/timeline';
import TechStack from '../components/tech-stack';
import Register from '../components/register';
import Footer from '../components/footer';

export default function Home() {
    return (
        <div>
            <Menu />
            <Landing />
            <Program />
            <Benefits />
            <Companies />
            <TeamFormation />
            <TechStack />
            <Timeline />
            <Register />
            <Footer />
        </div>
    )
}