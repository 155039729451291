import { Typography } from '@mui/material';

export default function Title(props) {
    const { children } = props;

    return (
        <Typography variant='h2' sx={{ mb: 5 }}>
            <i><b><span className='tag'>&lt;</span>{children}<span className='tag'>/&gt;</span></b></i>
        </Typography>
    )
}