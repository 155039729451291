import React from 'react';
import {
    AppBar,
    Button,
    Box,
    Container,
    Grid,
    Toolbar,
    IconButton,
    Typography,
    Menu as AppMenu,
    MenuItem,
} from '@mui/material';

import MenuIcon from '@mui/icons-material/Menu';

const pages = [
    'Хөтөлбөр',
    'Үр өгөөж',
    'Байгууллагууд',
    'Баг Бүрдүүлэлт',
    'Бүртгүүлэх'
]

const domIds = [
    'program',
    'benefits',
    'companies',
    'team-formation',
    'register',
]

export default function Menu() {
    const [anchorElementNav, setAnchorElementNav] = React.useState(null);

    function handleOpenNavMenu(event) {
        setAnchorElementNav(event.currentTarget);
    }

    function handleCloseNavMenu() {
        setAnchorElementNav(null)
    }

    function scrollToDocumentById(domIds) {
        document.getElementById(domIds).scrollIntoView({
            behavior: 'smooth',
            block: 'center',
            inline: 'center'
        })
    }

    return (
        <div id='menu'>
            {/* DESKTOP APPBAR - START */}
            <AppBar position='fixed' sx={{ display: { xs: 'none', md: 'block' }, boxShadow: 0, background: '#090909' }}>
                <Container>
                    <Grid container justifyContent='space-between' alignItems='center' sx={{ textAlign: 'right' }}>
                        <Grid item xs={12}>
                            <Box>
                                {pages.map((page, index) => (
                                    <Button
                                        key={page}
                                        sx={{ my: 2, p: 2, display: 'inline-block', textTransform: 'initial' }}
                                        color='inherit'
                                        onClick={() => scrollToDocumentById(domIds[index])}
                                    >
                                    {page}
                                    </Button>
                                ))}
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </AppBar>
            {/* DESKTOP APPBAR - END */}

            {/* MOBILE APPBAR - START */}
            <AppBar position='fixed' sx={{ display: { xs: 'block', md: 'none' }, boxShadow: 0, background: '#090909' }}>
                <Toolbar>
                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        onClick={handleOpenNavMenu}
                        sx={{ mr: 2 }}
                    >
                        <MenuIcon />
                    </IconButton>
                </Toolbar>
                <AppMenu
                    id="menu-appbar"
                    anchorEl={anchorElementNav}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    keepMounted
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    open={Boolean(anchorElementNav)}
                    onClose={handleCloseNavMenu}
                    sx={{
                        display: { xs: 'block', md: 'none' },
                    }}
                >
                    {pages.map((page, index) => (
                        <MenuItem key={page} onClick={() => scrollToDocumentById(domIds[index])}>
                            <Typography textAlign="center">{page}</Typography>
                        </MenuItem>
                    ))}
                </AppMenu>
            </AppBar>
            {/* MOBILE APPBAR - END */}
        </div>
    )
}