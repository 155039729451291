import {
    Container,
    Grid
} from '@mui/material';

import Title from './title';
import Context from './context';

import Decoration1 from '../assets/decoration1.png';

export default function Benefits() {
    return (
        <div id='benefits'>
            <Container maxWidth='lg' sx={{ my: 20 }}>
                <img className='decoration1' src={Decoration1} alt='Decoration 1' />
                <Grid container>
                    <Grid item xs={12} md={6}></Grid>
                    <Grid item xs={12} md={6}>
                        <Title>Үр өгөөж</Title>
                        <Context>
                            Монголын <b>ТОП</b> компаниудад дижитал
                            шилжилтээ хурдасгах, тулгарч буй асуудлуудаа
                            мэдээллийн технологийг ашиглан шийдэх хэрэгцээ
                            шаардлага байдаг. <b>Эрксис нь open source
                            платформ</b> бөгөөд түүнтэй зохицон ажиллах нэмэлт
                            plug-in-уудыг хөгжүүлснээр, тухайн бизнесийн
                            онцлогт тохирсон, өөр хаана ч байхгүй IT шийдлийг
                            хөгжүүлэх боломжтой юм.
                        </Context>
                    </Grid>
                </Grid>
            </Container>
        </div>
    )
}