import {
    Link,
    Container,
    Typography,
    Grid
} from '@mui/material';

import Title from './title';
import Context from './context';

import Freebie from '../assets/freebie.png'
import FreebieLogo from '../assets/freebie-logo.png'

export default function Register() {
    return (
        <div id='register'>
            <Container sx={{ mt: 20 }}>
                <Grid container alignItems='center' rowSpacing={4}>
                    <Grid item xs={12} md={5}>
                        <Title>Бүртгүүлэх</Title>
                        <Context>
                            Хөтөлбөрт хамрагдаж зохион байгуулагч болон байгууллагын гарын бэлэг болон ажилд орох саналаа аваарай.
                        </Context>
                        <Typography variant='h4' sx={{ mt: 8 }}>
                            <Link
                                data-erxes-modal='s3Gs84'
                                color='inherit'
                                sx={{
                                    '&:hover': {
                                        cursor: 'pointer'
                                    }
                                }}
                            >
                                Анкет бөглөх
                            </Link>
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={7}>
                        <Typography variant='h4' sx={{ textAlign: 'center', position: 'relative' }}>
                            <img className='freebie' src={Freebie} alt='Freebie' />
                            <img className='freebie-logo' src={FreebieLogo} alt='Freebie Logo' />
                        </Typography>
                    </Grid>
                </Grid>
            </Container>
        </div>
    )
}