import { createTheme, responsiveFontSizes } from '@mui/material/styles';

const darkTheme = createTheme({
    palette: {
        mode: 'dark',
        primary: {
            main: '#ee5928',
            light: '#ff8b55',
            dark: '#b42400'
        }
    },
    typography: {
        fontFamily: [
            'Ubuntu'
        ].join(','),
        h6: {
            fontWeight: 400,
        },
    },
    shape: {
        borderRadius: 0,
    },
    components: {
        MuiButtonBase: {
            defaultProps: {
                disableRipple: true,
            },
        },
    },
});

export default responsiveFontSizes(darkTheme);
