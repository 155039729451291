import {
    Container,
    Box,
    Grid
} from '@mui/material';

import Title from './title';
import Context from './context';

import MernLogo from '../assets/mern.png';

export default function TechStack() {
    return (
        <Container sx={{ my: 20 }}>
            <Title>Tech Stack</Title>
            <Grid container justifyContent='space-between' rowSpacing={4}>
                <Grid item xs={12} md={5}>
                    <Context>
                        Дадлага хийх хугацаанд багууд
                        суурь систем дээр мэргэшиж ур
                        чадвараа нэмэгдүүлнэ.
                    </Context>
                </Grid>
                <Grid item xs={12} md={5}>
                    <Box
                        sx={{
                            textAlign: 'center',
                            filter: 'brightness(0) invert(0.8)',
                        }}
                    >
                        <img className='tech-stack' src={MernLogo} alt='MERN Logo' />
                    </Box>
                </Grid>
            </Grid>
        </Container>
    )
}