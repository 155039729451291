import {
    Box,
    Container,
    Typography,
    Grid
} from '@mui/material';

import BootcampLogo from '../assets/bootcamp.svg';

export default function Landing() {
    return (
        <div className='landing'>
            <Container maxWidth='lg' sx={{ mt: 12, display: { xs: 'none', md: 'block' } }}>
                <Grid container justifyContent='right'>
                    <Grid item>
                        <Typography variant='h3'><b>May 28 - Aug 31, <span className='tag'>2022</span></b></Typography>
                    </Grid>
                </Grid>
                <Box sx={{ m: -8, mt: 4 }}>
                    <img src={BootcampLogo} alt='BootcampLogo' width='800px' />
                </Box>
            </Container>

            <Container maxWidth='lg' sx={{ mt: 15, display: { xs: 'block', md: 'none' } }}>
                <Grid container justifyContent='center' alignItems='center' rowSpacing={10}>
                    <Grid item xs={12} sx={{ textAlign: 'right' }}>
                        <Typography variant='h4'><b>May 28 - Aug 31, <span className='tag'>2022</span></b></Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Box>
                            <img src={BootcampLogo} alt='BootcampLogo' width='80%' />
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </div>
    )
}