import {
    Box,
    Container,
    Grid,
    Link,
    Typography
} from '@mui/material';

import BootcampLogo from '../assets/bootcamp.svg';

export default function Footer() {

    function scrollToDocumentById(domIds) {
        document.getElementById(domIds).scrollIntoView({
            behavior: 'smooth',
            block: 'center',
            inline: 'center'
        })
    }

    return (
        <Container maxWidth='lg'>
            <hr />
            <Grid container sx={{ my: 5 }} rowSpacing={2}>
                <Grid item xs={12} sm={6} md={6}>
                    <Box sx={{ m: -2, mt: -6 }}>
                        <img src={BootcampLogo} alt='Bootcamp logo' width='250px' />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={3} md={3}>
                    <Typography variant='body1'>
                        <Link href='#' color='inherit' underline='hover'>Instagram</Link><br/>
                        <Link href='#' color='inherit' underline='hover'>Twitter</Link><br/>
                        <Link href='#' color='inherit' underline='hover'>Email</Link><br/>
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={3} md={3}>
                    <Typography variant='body1'>
                        <Link component='button' variant='body1' color='inherit' underline='hover' onClick={() => scrollToDocumentById('program')}>Хөтөлбөр</Link><br/>
                        <Link component='button' variant='body1' color='inherit' underline='hover' onClick={() => scrollToDocumentById('benefits')}>Үр өгөөж</Link><br/>
                        <Link component='button' variant='body1' color='inherit' underline='hover' onClick={() => scrollToDocumentById('companies')}>Ивээн тэтгэгчид</Link><br/>
                        <Link component='button' variant='body1' color='inherit' underline='hover' onClick={() => scrollToDocumentById('register')}>Бүртгүүлэх</Link><br/>
                    </Typography>
                </Grid>
                <Grid item xs={12} md={12}>
                    <Typography variant='body1'>
                        &copy; 2022 Copyright erxes Inc All rights reserved
                    </Typography>
                </Grid>
            </Grid>
        </Container>
    )
}